import React from "react"
import Layout from "../components/layout/Layout"
import WideContainer from "../components/layout/WideContainer"
import MetaData from "../components/seo/MetaData"
import { NationalPark, Sight } from "../models/NationalPark"
import SightCard from "../components/cards/SightCard"
import ParkSectionText from "../components/nationalpark/ParkSectionText"

interface Props {
  pageContext: PageContext
  location: any
}

interface PageContext {
  sights: Sight[]
  park: NationalPark
}

const SightsTemplate: React.FunctionComponent<Props> = ({
  pageContext,
  location,
}) => {
  return (
    <Layout>
      <MetaData
        title={`Sights in ${pageContext.park.national_park_name[0].text}`}
        description={`Sights in ${pageContext.park.national_park_name[0].text}. Read more about the sights and get directions to visit.`}
        thumbnailUrl={pageContext.sights[0].sight_image.url}
        imageAlt={`${pageContext.sights[0].sight_image.alt}`}
        pageUrl={location.pathname}
      />
      <div className={`container mx-auto px-4`}>
        <h1 className="mt-8 md:mb-0 mb-2">
          Explore sights in {pageContext.park.national_park_name[0].text}
        </h1>
        <p className="mt-2 mb-4">
          Read more about the sights in the national park. Select a sight and
          see more details.
        </p>
      </div>
      <WideContainer center={false}>
        <div className="grid grid-cols-1 md:grid-cols-3 py-4">
          {pageContext.sights.map((element, index) => {
            return (
              <SightCard
                key={index.toString()}
                sight={element}
                parkname={pageContext.park.national_park_name[0].text}
              />
            )
          })}
        </div>
      </WideContainer>
      <ParkSectionText />
    </Layout>
  )
}

export default SightsTemplate
